<template>
  <div>
    <basic-input
      v-model="reason.label"
      :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
      :horizontal="true"
      label="Bezeichnung"
      required
    />
    <basic-checkbox
      v-model="reason.allowCustomText"
      :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
      :horizontal="true"
      label="Erlaube Freitextfeld"
    />
  </div>
</template>

<script>
import BasicInput from '@/components/BaseComponents/BasicInput'
import BasicCheckbox from '@/components/BaseComponents/BasicCheckbox'
export default {
  components: {
    BasicInput,
    BasicCheckbox
  },
  props: {
    reason: {
      type: Object,
      required: true
    }
  }
}
</script>

<style>

</style>
