<template>
  <modal
    id="editModal"
    size="md"
    title="Blacklist-Grund bearbeiten"
    :closable="true"
    @close="closeModal(false)"
  >
    <template #body>
      <transition name="slide-fade">
        <ca-alert
          v-if="errorMessage"
          variant="danger"
          :message="errorMessage"
          @close="errorMessage = null"
        />
      </transition>
      <BlacklistReasonForm
        v-if="!loading"
        :reason="reason"
      />
    </template>
    <template
      #footer
    >
      <button
        id="delete-reason-button"
        class="btn hover-gray-2 text-danger"
      >
        Löschen
      </button>
      <button
        class="btn btn-primary float-right ml-2"
        @click="save()"
      >
        Speichern
      </button>
      <button
        class="btn hover-gray-2 text-primary float-right"
        @click="closeModal(false)"
      >
        Abbrechen
      </button>
      <b-popover
        ref="deleteReasonPopover"
        target="delete-reason-button"
        placement="top"
        container="editModal"
        title="Sind Sie sich sicher?"
        triggers="click"
      >
        <p>
          Sind Sie sicher, dass Sie den Grund löschen wollen?
        </p>
        <button
          class="btn hover-gray-2 text-primary w-100"
          @click.stop="$refs.deleteReasonPopover.$emit('close')"
        >
          Abbrechen
        </button>
        <button
          class="btn hover-gray-2 text-danger w-100"
          @click.stop="remove()"
        >
          Löschen
        </button>
      </b-popover>
    </template>
  </modal>
</template>

<script>
import Modal from '@/components/Modal'
import BlacklistReasonForm from '@/components/Forms/BlacklistReasonForm'
export default {
  $_veeValidate: {
    validator: 'new' // give me my own validator scope.
  },
  components: {
    Modal,
    BlacklistReasonForm
  },
  props: {
    reasonId: {
      type: String,
      required: true
    }
  },
  data: () => ({
    loading: true,
    savePending: false,
    reason: {},
    errorMessage: null
  }),
  watch: {
    reasonId: {
      handler: 'fetchData',
      immediate: true
    }
  },
  mounted: function () {
    this.fetchData()
  },
  methods: {
    closeModal (success) {
      this.$emit('close', success)
    },
    async fetchData () {
      try {
        this.loading = true
        this.reason = await this.$store.dispatch('blacklisted-chassis-reasons/get', this.reasonId)
        this.reason.authorId = this.$store.getters['auth/user'].user._id
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    async save () {
      try {
        const valid = await this.$validator.validateAll()
        if (this.loading || this.savePending || !valid) {
          return
        }
        this.savePending = true
        await this.reason.save()
        this.closeModal(true)
      } catch (error) {
        console.error(error.message)
      } finally {
        this.savePending = false
      }
    },
    async remove () {
      this.$refs.deleteReasonPopover.$emit('close')
      try {
        await this.$store.dispatch('blacklisted-chassis-reasons/remove', this.reasonId)
        this.closeModal(true)
      } catch (error) {
        this.errorMessage = error.message
        console.error(error)
      }
    }
  }
}
</script>

<style>

</style>
