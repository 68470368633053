<template>
  <div>
    <BlacklistReasonCreateModal
      v-if="showReasonCreateModal"
      @close="reasonCreateModalClosed($event)"
    />
    <BlacklistReasonEditModal
      v-if="showReasonEditModal"
      :reason-id="editedReasonId"
      @close="reasonEditModalClosed($event)"
    />
    <ca-header
      heading="Blacklist Fahrgestellnummern"
      :column-layout="true"
    >
      <template #buttonSpace>
        <router-link
          v-if="activeTab === 0"
          class="btn btn-primary"
          :to="{name: 'BlacklistedChassisCreate' }"
        >
          Neuen Eintrag hinzufügen
        </router-link>
        <button
          v-else
          class="btn btn-primary"
          @click="showReasonCreateModal = true"
        >
          Neuen Grund hinzufügen
        </button>
      </template>
    </ca-header>

    <div class="container-fluid px-4 pt-4">
      <div class="row">
        <div class="col-12">
          <b-tabs
            v-model="activeTab"
            class="tabs"
          >
            <b-tab
              title="Gesperrte Fahrgestellnummern"
              lazy
            >
              <div class="bg-white border border-gray-2 border-top-0 rounded-bottom mb-3">
                <data-table
                  ref="chassis"
                  :fields="blacklistFields"
                  service="blacklisted-chassis"
                  card-as-tab
                  @row-clicked="(item) => $router.push({name: 'BlacklistedChassisEdit', params: {blacklistedChassisId: item._id}})"
                />
              </div>
            </b-tab>
            <b-tab
              title="Gründe"
              lazy
              :active="this.$route.name === 'BlacklistedChassisReasonsList'"
            >
              <div class="bg-white border border-gray-2 border-top-0 rounded-bottom mb-3">
                <data-table
                  ref="reasons"
                  :fields="reasonFields"
                  service="blacklisted-chassis-reasons"
                  card-as-tab
                  @row-clicked="(item) => {
                    showReasonEditModal = true
                    editedReasonId = item._id
                  }"
                />
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CaHeader from '@/components/Layout/Header'
import DataTable from '@/components/DataTable/DataTable'
import DateFormatter from '../../filters/formatDate'
import BlacklistReasonCreateModal from '@/components/Modals/Blacklist/BlacklistReasonCreateModal'
import BlacklistReasonEditModal from '@/components/Modals/Blacklist/BlacklistReasonEditModal'
export default {
  name: 'BlacklistedChassisList',
  components: {
    DataTable,
    CaHeader,
    BlacklistReasonCreateModal,
    BlacklistReasonEditModal
  },
  data: () => ({
    showReasonCreateModal: false,
    showReasonEditModal: false,
    editedReasonId: null,
    activeTab: 0,
    blacklistFields: [
      {
        key: 'active',
        label: 'Status',
        formatter: value => value ? 'aktiv' : 'inaktiv',
        filter: {
          type: 'LIST',
          defaultValue: [{ value: true }],
          fetchOptions: () => ([
            { value: false },
            { value: true }
          ])
        }
      },
      {
        key: 'chassi',
        label: 'Fahrgestellnummer'
      },
      {
        key: 'reason.label',
        label: 'Grund'
      },
      {
        key: 'updatedAt',
        label: 'Zuletzt bearbeitet am',
        formatter: value => DateFormatter(value, 'DD.MM.YYYY')
      },
      {
        key: 'author',
        label: 'Zuletzt bearbeitet von',
        formatter: value => value.firstname ? value.firstname + ' ' + value.lastname : value.lastname
      }
    ],
    reasonFields: [
      {
        key: 'label',
        label: 'Bezeichnung'
      },
      {
        key: 'allowCustomText',
        label: 'Freitextfeld',
        formatter: value => value ? 'Erlaubt' : 'nicht erlaubt'
      },
      {
        key: 'updatedAt',
        label: 'Zuletzt geändert am',
        formatter: value => DateFormatter(value, 'DD.MM.YYYY')
      },
      {
        key: 'author',
        label: 'Zuletzt bearbeitet von',
        formatter: (value) => {
          if (value) {
            return (value.firstname ? value.firstname + ' ' : '') + value.lastname
          } else {
            return 'kein Eintrag'
          }
        }
      }
    ]
  }),
  watch: {
    activeTab: {
      handler: 'tabChanged'
    }
  },
  methods: {
    tabChanged () {
      this.$router.replace({ name: this.activeTab === 0 ? 'BlacklistedChassisList' : 'BlacklistedChassisReasonsList' })
    },
    reasonCreateModalClosed (success) {
      if (success) {
        this.$refs.reasons.debouncedFetchData()
      }
      this.showReasonCreateModal = false
    },
    reasonEditModalClosed (success) {
      if (success) {
        this.$refs.reasons.debouncedFetchData()
      }
      this.showReasonEditModal = false
    }
  }
}
</script>

<style lang="scss" scoped>

.tabs ::v-deep{
  .tab-pane:focus {
    outline: none;
  }
}
</style>
