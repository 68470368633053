<template>
  <modal
    size="md"
    title="Blacklist-Grund hinzufügen"
    :closable="true"
    @close="closeModal(false)"
  >
    <template #body>
      <BlacklistReasonForm
        :reason="reason"
      />
    </template>
    <template
      #footer
    >
      <button
        class="btn btn-primary float-right ml-2"
        @click="save()"
      >
        Erstellen
      </button>
      <button
        class="btn hover-gray-2 text-primary float-right"
        @click="closeModal(false)"
      >
        Abbrechen
      </button>
    </template>
  </modal>
</template>

<script>
import Modal from '@/components/Modal'
import BlacklistReasonForm from '@/components/Forms/BlacklistReasonForm'
export default {
  $_veeValidate: {
    validator: 'new' // give me my own validator scope.
  },
  components: {
    Modal,
    BlacklistReasonForm
  },
  data: () => ({
    savePending: false,
    reason: {}
  }),
  mounted: function () {
    this.fetchData()
  },
  methods: {
    closeModal (success) {
      this.$emit('close', success)
    },
    async fetchData () {
      try {
        this.reason = new this.$FeathersVuex.api.BlacklistedChassisReasons()
        this.reason.authorId = this.$store.getters['auth/user'].user._id
      } catch (error) {
        console.error(error.message)
      }
    },
    async save () {
      try {
        const valid = await this.$validator.validateAll()
        if (this.savePending || !valid) {
          return
        }
        this.savePending = true
        await this.reason.save()
        this.closeModal(true)
      } catch (error) {
        console.error(error.message)
      } finally {
        this.savePending = false
      }
    }
  }
}
</script>

<style>

</style>
